import React from "react";
import NavBar from "./NavBar";
import LandingPage from "./LandingPage";
import Summary from "./Summary";
import BestSpots from "./BestSpots";
import Programs from "./Programs";
import AboutMe from "./AboutMe";
import Events from "./Events";
import ContactMe from "./ContactMe";
import Footer from "./Footer";

const Homepage = () => {
  return (
    <div>
      <NavBar />
      <LandingPage />
      <Summary />
      <BestSpots />
      <Programs />
      <Events />
      <AboutMe />
      <ContactMe />
      <Footer />
    </div>
  );
};

export default Homepage;
