import React, { useRef, useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import BestSpotsCarouselControls from './BestSpotsCarouselControls';
import useInView from '../hooks/useInView';
import { images, content } from './BestSpotsContent';
import FadeInEffect from './FadeInEffect';

const BestSpots = () => {
  const sliderRef = useRef(null);
  const bestSpotsRef = useRef(null);
  const isInView = useInView(bestSpotsRef);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    const handleCategorySelected = (event) => {
      const category = event.detail;
      const categoryIndex = content.findIndex((item) => item.category === category);
      setSelectedCategory(category);

      // Scroll to the BestSpots section
      bestSpotsRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    window.addEventListener('categorySelected', handleCategorySelected);

    return () => {
      window.removeEventListener('categorySelected', handleCategorySelected);
    };
  }, []);

  useEffect(() => {
    if (selectedCategory && sliderRef.current) {
      const categoryIndex = content.findIndex((item) => item.category === selectedCategory);
      if (categoryIndex !== -1) {
        requestAnimationFrame(() => {
          const slidesToShow = 4; // Adjust this if your slidesToShow setting is different
          const totalSlides = content.length;
          let targetSlide;

          if (categoryIndex === 0) {
            // For the first item (CULTURE), move to the last set of slides
            targetSlide = totalSlides - slidesToShow + 3;
          } else {
            // For other items, keep the current logic
            targetSlide = Math.max(0, categoryIndex - 1);
          }

          sliderRef.current.slickGoTo(targetSlide);
        });
      }
    }
  }, [selectedCategory, isInView]);

  // Slick slider settings
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: window.innerWidth < 768 ? 0.9 : 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
    variableWidth: false, // Disable variableWidth for consistent slide widths
    afterChange: (currentSlide) => {
      // Reset selectedCategory after carousel has moved
      setSelectedCategory(null);
    },
  };

  return (
    <div
      className="pt-10 md:pt-28 h-screen w-screen flex-col space-y-0 md:space-y-12 bg-brand-yellow md:bg-white"
      id="bestSpots"
      ref={bestSpotsRef}
    >
      {/* TITLE */}
      <div className="w-full text-center">
        <FadeInEffect isInView={isInView} disableTranslate={true}>
          <div className="bg-brand-yellow md:bg-white text-left md:text-center pl-8 md:pl-0 font-light md:text-[2rem] tracking-[0.4rem] md:tracking-[0.7rem]">
            THE BEST SPOTS OF BURGUNDY
          </div>
        </FadeInEffect>
      </div>
      {/* CAROUSEL AND WHITE BOX TEXT*/}
      <div className=" relative w-full h-7/12 md:h-[67%] flex flex-col md:flex-row">
        <div className="md:absolute top-0 left-20 h-1/2 md:h-full md:w-[32%] overflow-auto pl-8 md:pl-14 pr-10 md:pr-14 text-left md:bg-white z-20">
          <FadeInEffect isInView={isInView} disableTranslate={true}>
            <div className="leading-none">
              {' '}
              <p className="text-white md:text-brand-yellow text-4xl md:text-header font-light">
                TOURS
              </p>
              <p className="text-white md:text-brand-yellow text-2xl md:text-3xl font-extralight">
                NOT LIKE THE OTHERS
              </p>
            </div>

            <div className="md:h-8"></div>
            <p className="text-black text-xl md:text-2xl font-semibold">
              Everyone can experience Burgundy!
            </p>
            <div className="md:h-6"></div>
            <p className="text-black text-sm md:text-xl font-light">
              Wine... but not only <br />I am here to help you live it like the locals
            </p>
            <p className="text-black text-xs md:text-sm font-light">
              Tell me what you are looking for and I organize your visit according to your wishes,
              for the whole family, no one excluded
            </p>
            <div className="md:h-2"></div>
            <div
              className="pt-4 md:pt-12 pb-2 text-[0.9rem] md:text-[1rem] font-extralight cursor-pointer"
              onClick={() => {
                const element = document.getElementById('programs');
                if (element) {
                  element.scrollIntoView({ behavior: 'smooth' });
                }
              }}
            >
              DISCOVER MORE
            </div>
          </FadeInEffect>
        </div>
        <div className="relative w-full h-[55vh]">
          <FadeInEffect isInView={isInView} disableTranslate={true}>
            {/* This is the react-slick carousel slider component which is used to display images and content in a sliding manner */}
            <Slider {...settings} ref={sliderRef}>
              {/* This content.map() is mapping through an array of content to generate each slide in the carousel */}
              {content.map((item, index) => (
                <div key={index} className="relative h-[56vh] bg-white pr-4">
                  <img src={images[index]} className="w-full h-full object-cover" />
                  <div className="absolute md:pt-0 pt-2 top-[12%] md:top-10 right-20 md:right-0 w-[50%] md:w-[52%] h-[52%] md:h-[55%] bg-white opacity-85 overflow-auto leading-snug">
                    <div className="pt-1 pr-6 pl-2 flex flex-col text-right">
                      <p className="font-bold text-lg">{item.category}</p>
                      <p className="text-xs font-light">
                        {item.subItems.map((subItem, subIndex) => (
                          <p key={subIndex}>{subItem}</p>
                        ))}
                      </p>
                      <div className="h-2"></div>
                      <p className="text-[0.7rem] font-medium">{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </FadeInEffect>

          {/* Custom Arrows for Mobile */}
          <div className="absolute bottom-10 left-4 right-4 flex justify-between md:hidden">
            {/* Left Arrow */}
            <div
              className="w-8 h-8 bg-[#fdc400] rounded-full flex items-center justify-center cursor-pointer"
              onClick={() => sliderRef.current.slickPrev()}
            >
              <FaChevronLeft className="text-black" />
            </div>

            {/* Right Arrow */}
            <div
              className="w-8 h-8 bg-[#fdc400] rounded-full flex items-center justify-center cursor-pointer"
              onClick={() => sliderRef.current.slickNext()}
            >
              <FaChevronRight className="text-black" />
            </div>
          </div>
        </div>
      </div>
      {/* ARROWS AND BOOK NOW BUTTON */}
      <FadeInEffect isInView={isInView} disableTranslate={true} className="hidden md:block">
        <div className="flex justify-around">
          <BestSpotsCarouselControls sliderRef={sliderRef} />
          <button
            className="border-[0.01rem] border-slate-900 px-2 text-sm font-light"
            onClick={() => {
              const landingPageElement = document.getElementById('contactme');
              if (landingPageElement) {
                landingPageElement.scrollIntoView({ behavior: 'smooth' });
              }
            }}
          >
            BOOK NOW
          </button>
        </div>
      </FadeInEffect>
    </div>
  );
};

export default BestSpots;
