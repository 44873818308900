import React, { useRef } from 'react';
import image from '../assets/aboutmenew.jpg';
import imageMobile from '../assets/imageMobile.png';
import useInView from '../hooks/useInView';

const AboutMe = () => {
  const aboutMeRef = useRef(null);
  const isInView = useInView(aboutMeRef); // Use the hook

  return (
    <div
      className=" relative w-screen min-h-[92vh] md:h-screen flex items-top md:p-0 pl-2 pr-4"
      id="aboutme"
      ref={aboutMeRef}
      style={{ overflow: 'hidden' }}
    >
      <img
        src={image}
        className={`hidden md:block w-[30%] h-[100%] object-cover transition-opacity duration-[1500ms] transform ${
          isInView ? 'opacity-100' : 'opacity-0'
        }`}
        style={{ objectPosition: '32% 100%' }}
      />
      <div
        className={`pl-6 space-y-[1.6rem] transition-opacity duration-[1500ms] transform ${
          isInView ? 'opacity-100' : 'opacity-0'
        }`}
      >
        <div className="h-4"></div>
        <div className="flex items-end space-x-4 md:space-x-0">
          <img
            src={image}
            className={`md:hidden block w-[50%] h-[100%] transition-opacity duration-[1500ms] transform ${
              isInView ? 'opacity-100' : 'opacity-0'
            }`}
          />

          <div className="text-brand-yellow text-header font-extralight">SYLVIE</div>
        </div>

        <p className="font-extralight text-base md:text-[3vmin] leading-snug">
          I was born here but I left Burgundy to study in Paris at 18.
          <span className="hidden md:inline">
            <br />
          </span>{' '}
          During 25 years, I worked as an art director in major advertising agencies.
          <span className="hidden md:inline">
            <br />
          </span>{' '}
          I lived in Lisbon for 5 years, then Rome for 16 years.
          <span className="hidden md:inline">
            <br />
          </span>{' '}
          There, I met my husband Marco, a sommelier and wine merchant and we had a son.
          <span className="hidden md:inline">
            <br />
          </span>{' '}
          In 2019, we decided to change life and we moved in Beaune.
          <span className="hidden md:inline">
            <br />
          </span>{' '}
          My job no longer satisfied me and I opted to work in a hotel as assistant manager.
          <span className="hidden md:inline">
            <br />
          </span>{' '}
          I often had to act as concierge and guide.
        </p>

        <p className="font-medium text-base md:text-[3vmin] leading-snug">
          Strong from all these experiences, I would love to share with you this intimate knowledge
          <span className="hidden md:inline">
            <br />
          </span>{' '}
          of the territory and introduce you an immersive way of travelling.
        </p>
        <p className="font-medium text-base md:text-[3vmin] leading-snug">
          I’m curious, cheerful, I love sharing experiences, emotions and opinions with people
          <span className="hidden md:inline">
            <br />
          </span>{' '}
          and make new friends all over the world.
        </p>
        <p className="font-medium text-xl md:text-[3vmin] ">I CAN'T WAIT TO MEET YOU</p>
      </div>
    </div>
  );
};

export default AboutMe;
