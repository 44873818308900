import React, { useState, useEffect, useRef } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './customCalendar.css'; // Custom CSS for calendar
import { FaChevronUp, FaChevronDown } from 'react-icons/fa'; // Import React Icons

const BookNow = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [numOfPax, setNumOfPax] = useState(2);
  const [tourType, setTourType] = useState('TOUR');
  const [mobileTourType, setMobileTourType] = useState('');
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setShowCalendar(false);
  };

  const incrementPax = () => setNumOfPax((prev) => (prev < 4 ? prev + 1 : prev));
  const decrementPax = () => setNumOfPax((prev) => Math.max(1, prev - 1));

  const cycleTourType = (direction) => {
    const tourTypes = ['PLAN FOR YOU', 'BE YOUR GUIDE'];
    const currentIndex = tourTypes.indexOf(tourType);
    const newIndex =
      direction === 'up'
        ? (currentIndex + 1) % tourTypes.length
        : (currentIndex - 1 + tourTypes.length) % tourTypes.length;
    setTourType(tourTypes[newIndex]);
  };

  const handleClickOutside = (event) => {
    if (calendarRef.current && !calendarRef.current.contains(event.target)) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const sendWhatsAppMessage = () => {
    const phoneNumber = '33650970570'; // Replace with the desired phone number in international format
    const message = `Hello, I would like to check availability for the following:\n\nDate: ${selectedDate.getDate()} ${selectedDate
      .toLocaleDateString('en-US', { month: 'short' })
      .toUpperCase()}\nNumber of Pax: ${numOfPax}\nTour Type: ${tourType}`;
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
    window.open(whatsappUrl, '_blank');
  };

  return (
    <div className="md:pt-0 flex flex-col text-right text-white space-y-2">
      <div className="border-b-[0.1rem] font-bold">
        <div className="text-base md:text-xl mb-0 md:mb-2">BOOK NOW</div>
      </div>

      {/* Date Picker */}
      <div className="relative flex justify-start md:justify-end items-center flex-row-reverse md:flex-row pr-1 md:pr-0">
        {/* Up and Down Buttons */}
        <div className="flex flex-col items-center">
          <button
            className="bg-gray-400 bg-opacity-20 rounded"
            onClick={() => setSelectedDate(new Date(selectedDate.getTime() + 86400000))}
            style={{ transform: 'scaleX(0.7)', fontWeight: '300' }} // Narrow and not bold
          >
            <FaChevronUp size={18} />
          </button>
          <button
            className="bg-gray-400 bg-opacity-20 rounded"
            onClick={() => setSelectedDate(new Date(selectedDate.getTime() - 86400000))}
            style={{ transform: 'scaleX(0.7)', fontWeight: '300' }} // Narrow and not bold
          >
            <FaChevronDown size={18} />
          </button>
        </div>

        {/* Date Display */}
        <div
          onClick={() => setShowCalendar(!showCalendar)}
          className="cursor-pointer text-3xl md:text-4xl mx-2"
        >
          {selectedDate.getDate()}{' '}
          {selectedDate.toLocaleDateString('en-US', { month: 'short' }).toUpperCase()}
        </div>

        {/* Calendar Pop-up */}
        {showCalendar && (
          <div
            ref={calendarRef}
            className="fixed top-0 left-0 w-[100vw] md:w-[50vh] bg-white rounded-lg shadow-lg z-50 p-4"
          >
            <Calendar onChange={handleDateChange} value={selectedDate} />
          </div>
        )}
      </div>

      {/* Number of Pax */}
      <div className="hidden md:flex justify-end items-center">
        <div className="flex flex-col items-center space-y-0">
          <button
            className="bg-gray-400 bg-opacity-20 rounded"
            onClick={incrementPax}
            style={{ transform: 'scaleX(0.7)', fontWeight: '300' }} // Narrow and not bold
          >
            <FaChevronUp size={10} />
          </button>
          <button
            className="bg-gray-400 bg-opacity-20 rounded"
            onClick={decrementPax}
            style={{ transform: 'scaleX(0.7)', fontWeight: '300' }} // Narrow and not bold
          >
            <FaChevronDown size={10} />
          </button>
        </div>
        <div className="mx-2 text-base md:text-xl font-semibold">{numOfPax} PAX</div>{' '}
        {/* Smaller font size */}
      </div>

      {/* Number of Pax Selector for mobile screens*/}
      <div className="md:hidden flex justify-end items-center">
        <select
          value={numOfPax}
          onChange={(e) => setNumOfPax(Number(e.target.value))}
          className="bg-gray-400 bg-opacity-20 text-base md:text-xl font-semibold rounded p-2"
        >
          {[1, 2, 3, 4].map((pax) => (
            <option key={pax} value={pax}>
              {pax} PAX
            </option>
          ))}
        </select>
      </div>

      {/* Type of Tour */}
      <div className="hidden md:flex justify-end items-center border-b-[0.1rem]">
        <div className="flex flex-col items-center space-y-0">
          <button
            className="bg-gray-400 bg-opacity-20 rounded"
            onClick={() => cycleTourType('up')}
            style={{ transform: 'scaleX(0.7)', fontWeight: '300' }} // Narrow and not bold
          >
            <FaChevronUp size={10} />
          </button>
          <button
            className="bg-gray-400 bg-opacity-20 rounded"
            onClick={() => cycleTourType('down')}
            style={{ transform: 'scaleX(0.7)', fontWeight: '300' }} // Narrow and not bold
          >
            <FaChevronDown size={10} />
          </button>
        </div>
        <div className="mx-2 text-base md:text-xl font-semibold">{tourType}</div>{' '}
        {/* Smaller font size */}
      </div>

      {/* Type of Tour Selector for mobile screens */}
      <div className="md:hidden flex justify-end items-center border-b-[0.1rem]">
        <select
          value={mobileTourType}
          onChange={(e) => {
            setMobileTourType(e.target.value);
            setTourType(e.target.value); // Also update the main tourType state
          }}
          className="bg-gray-400 bg-opacity-20 text-base md:text-xl font-semibold rounded p-2 select-right" // Added custom CSS class
        >
          {!mobileTourType && (
            <option value="" disabled hidden>
              TOUR
            </option>
          )}
          {['PLAN FOR YOU', 'BE YOUR GUIDE'].map((type) => (
            <option key={type} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>

      {/* Check Availability Button */}
      <div>
        <button
          className="border-[0.01rem] mt-2 md:mt-4 p-1 text-sm md:text-xl font-semibold"
          onClick={sendWhatsAppMessage}
        >
          CHECK AVAILABILITY
        </button>
      </div>
    </div>
  );
};

export default BookNow;
