import React from "react";
const BestSpotsCarouselControls = ({ sliderRef }) => {
  const handlePrevious = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  return (
    <div>
      {/* Carousel Controls */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {/* Left Arrow with Solid Line */}
        <button
          onClick={handlePrevious}
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "1rem", // Adjust font size as needed
            fontWeight: "",
            background: "none",
            border: "none",
            cursor: "pointer",
            padding: "0",
          }}
        >
          <span
            style={{
              display: "inline-block",
              marginTop: "-1px",
              marginRight: "-8px", // Slight overlap to join the arrowhead to the line
            }}
          >
            {`<`}
          </span>
          <span
            style={{
              display: "inline-block",
              width: "80px", // Adjust width for line length
              borderTop: "1px solid black", // Solid line
            }}
          ></span>
        </button>
        <div style={{ width: "45px" }}></div> {/* Adjust width for spacing */}
        {/* Right Arrow with Solid Line */}
        <button
          onClick={handleNext}
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "1rem", // Adjust font size as needed
            fontWeight: "",
            background: "none",
            border: "none",
            cursor: "pointer",
            padding: "0",
          }}
        >
          <span
            style={{
              display: "inline-block",
              width: "80px", // Adjust width for line length
              borderTop: "1px solid black", // Solid line
              marginRight: "-6px", // Slight overlap to join the line to the arrowhead
            }}
          ></span>
          <span style={{ marginTop: "-1px" }}>{`>`}</span>
        </button>
      </div>
    </div>
  );
};

export default BestSpotsCarouselControls;
