import React, { useState, useEffect } from 'react';

const LeafLoader = () => {
  const [activeQuadrant, setActiveQuadrant] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveQuadrant((prev) => (prev + 1) % 4);
    }, 600); // Change quadrant every 300ms

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="leaf-loader">
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <circle cx="50" cy="50" r="48" className="leaf-background" />
        <path
          className={`quadrant ${activeQuadrant === 0 ? 'active' : ''}`}
          d="M50 2 C50 50 98 50 98 50 C50 50 50 2 50 2"
        />
        <path
          className={`quadrant ${activeQuadrant === 1 ? 'active' : ''}`}
          d="M98 50 C50 50 50 98 50 98 C50 50 98 50 98 50"
        />
        <path
          className={`quadrant ${activeQuadrant === 2 ? 'active' : ''}`}
          d="M50 98 C50 50 2 50 2 50 C50 50 50 98 50 98"
        />
        <path
          className={`quadrant ${activeQuadrant === 3 ? 'active' : ''}`}
          d="M2 50 C50 50 50 2 50 2 C50 50 2 50 2 50"
        />
      </svg>
    </div>
  );
};

export default LeafLoader;