import React, { useRef } from 'react';
import image from '../assets/contactme.jpg';
import useInView from '../hooks/useInView';
import ContactMeForm from './ContactMeForm';

const ContactMe = () => {
  const contactMeRef = useRef(null);
  const isInView = useInView(contactMeRef);

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://static.elfsight.com/platform/platform.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <div className="hidden h-full w-screen md:flex flex-col ">
        <div
          className="w-[100%] md:h-[70%] flex items-end"
          id="contactme"
          ref={contactMeRef}
          style={{ overflow: 'hidden' }}
        >
          <img
            src={image}
            className={`w-[50%] h-[100%] object-cover transition-opacity duration-[1500ms] transform ${
              isInView ? 'opacity-100' : 'opacity-0 '
            }`}
            style={{ objectPosition: '75% 100%' }}
          />
          <ContactMeForm />
        </div>
   
        <div className="w-4/5 mx-auto h-[30%]  flex space-x-12 pt-20 pl-8 pr-8 pb-20">
          <div 
            className="elfsight-app-facb108c-e948-441f-a19a-08d64a97ca6f w-full" 
            data-elfsight-app-lazy
            style={{ minHeight: '200px', position: 'relative' }}
          >
          </div>
        </div>
      </div>

      <div className="md:hidden flex-col h-full mb-10">
        <div className=" h-[30%]" id="contactmeMobile">
          {' '}
          <img src={image} className="w-screen h-full" />
        </div>
        <div className="h-[50%]">
          <ContactMeForm />
        </div>
        <div className="w-[90%] mx-auto h-[20%] flex space-x-0 md:space-x-8 pl-4 md:pl-8 pr-2 md:pr-8 pb-4 md:pb-8 mt-10">
          <div 
            className="elfsight-app-facb108c-e948-441f-a19a-08d64a97ca6f w-full" 
            data-elfsight-app-lazy
            style={{ minHeight: '150px', position: 'relative' }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default ContactMe;
