import React, { useRef, useState } from 'react';
import { Transition } from '@headlessui/react';
import LeafLoader from './LeafLoader';

const ContactMeForm = () => {
  const formRef = useRef(null);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    date: '',
    message: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDateChange = (e) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      date: value ? new Date(value).toLocaleDateString('en-GB') : '',
    });
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch('https://daidai-backend.vercel.app/api/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
        credentials: 'include',
      });

      if (response.ok) {
        console.log('Success:', await response.json());
        setShowModal(true);
        setFormData({
          name: '',
          email: '',
          date: '',
          message: '',
        });
      } else {
        console.error('Error:', await response.text());
        // You might want to show an error message to the user here
      }
    } catch (error) {
      console.error('Error:', error);
      // You might want to show an error message to the user here
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form
        ref={formRef}
        onSubmit={handleSubmit}
        className="w-screen md:w-[40%] h-full flex flex-col justify-end pl-10 pr-10 md:pr-0 md:pl-6 pb-1 md:space-y-1 space-y-3 md:pt-0 pt-5"
      >
        <div className="text-brand-yellow text-4xl md:text-header font-extralight">CONTACT ME</div>
        {/* Name Input */}
        <input
          type="text"
          name="name"
          placeholder="name"
          value={formData.name}
          onChange={handleChange}
          className="border border-gray-400 text-xl focus:outline-none placeholder-black placeholder:font-light pl-1 placeholder:text-lg"
        />

        {/* Email Input */}
        <input
          type="email"
          name="email"
          placeholder="email"
          value={formData.email}
          onChange={handleChange}
          className="border border-gray-400 text-xl focus:outline-none placeholder-black placeholder:font-light pl-1 placeholder:text-lg"
        />

        {/* Date of Stay */}
        <div className="relative">
          <input
            type="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            className="border border-gray-400 text-xl focus:outline-none placeholder-black placeholder:font-light pl-1 placeholder:text-lg w-full appearance-none h-8 bg-white"
            required
          />
          {!formData.date && (
            <span className="absolute left-1 top-1/2 transform -translate-y-1/2 text-lg text-black font-light pointer-events-none">
              dd/mm/yyyy
            </span>
          )}
        </div>

        {/* Message Box */}
        <textarea
          name="message"
          placeholder="message"
          value={formData.message}
          onChange={handleChange}
          className="border border-gray-400 text-xl focus:outline-none  placeholder-black placeholder:font-light pl-1 placeholder:text-lg"
          rows="9"
        />

        {/* Send Button */}
        <button
          type="submit"
          className="text-sm font-bold cursor-pointer text-left md:pb-0 pb-4"
          disabled={isLoading}
        >
          SEND
        </button>
      </form>

      {/* Loading Overlay */}
      {isLoading && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <LeafLoader />
        </div>
      )}

      {/* Modal */}
      <Transition show={showModal} as={React.Fragment}>
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as="div"
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              className="fixed inset-0 transition-opacity"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </Transition.Child>

            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as="div"
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-4 scale-95"
              className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg"
            >
              <h3 className="text-lg font-medium leading-6 text-gray-900">Thank you!</h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  Thank you for your message. I will answer you shortly. Have a great day ahead!
                </p>
              </div>
              <div className="mt-4">
                <button
                  type="button"
                  className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
                  onClick={closeModal}
                >
                  Close
                </button>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Transition>
    </>
  );
};

// Helper function to format date for input
const formatDateForInput = (dateString) => {
  const [day, month, year] = dateString.split('/');
  return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

export default ContactMeForm;
