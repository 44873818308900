export const images = [
  require('../assets/culture.jpg'), // CULTURE
  require('../assets/tasting.jpg'), // TASTING
  require('../assets/wellness.jpg'), // WELLNESS
  require('../assets/shopping.jpg'), // SHOPPING
  require('../assets/gastronomy.jpg'), // GASTRONOMY
  require('../assets/family.jpg'), // FAMILY
  require('../assets/sports.jpg'), // SPORTS]
];

export const content = [
  {
    category: 'CULTURE',
    subItems: ['MUSEUMS', 'CASTLES', 'CONCERTS', 'CHALON DANS LA RUE', 'EVENTS'],
    description:
      'Burgundy is rich in culture and amazing places to discover. You can be surprised!',
  },
  {
    category: 'TASTING',
    subItems: [
      'WINE',
      'BUT ALSO OTHERS',
      'BURGUNDIAN SPECIALITIES',
      'LIKE CHEESE',
      'SNAILS',
      'FROGS',
    ],
    description:
      'I can organise winetastings at winegrowers but take advantage of your visit to discover other Burgundy specialities.',
  },
  {
    category: 'WELLNESS',
    subItems: ['MASSAGES', 'YOGA', 'MEDITATION', 'HEALTH NEEDS', 'THERMAL BATHS', 'CHILLING'],
    description:
      'Holidays also involve rest and taking care of yourself. We want the best for you, and the best is not necessarily the best known.',
  },
  {
    category: 'SHOPPING',
    subItems: ['SOUVENIRS', 'PRODUCTS OF EXCELLENCE', 'NECESSARY'],
    description:
      'No one likes the feeling of being ripped off by buying tourists products made in China. Let me recommend the right shops for you.',
  },
  {
    category: 'GASTRONOMY',
    subItems: ['BURGERS', 'STAR MICHELIN RESTAURANTS', 'ETHNIC', 'PICNIC', 'TRADITIONAL'],
    description:
      'Gastronomy can be enjoyed in various ways. Tell me what you like, I will tell you where to eat.',
  },
  {
    category: 'FAMILY',
    subItems: ['LAKES', 'TREE CLIMBING', 'AMUSEMENT PARKS', 'CHILD ACTIVITIES', 'TREASURE HUNTS'],
    description: 'Why not enjoy a day together to have fun as a family?',
  },
  {
    category: 'SPORTS',
    subItems: ['CYCLING', 'SWIMMING', 'CLIMBING', 'TENNIS', 'GOLF', 'TRAIL', 'GO-KARTING'],
    description:
      'Burgundians love sports! You can join us and program a day (or more) of your favorite activity.',
  },
];
