// src/components/CookieConsent.js
// I have built a template, just in case in future your site you wish to insert google analytics, then please replace with your real google analytics ID. right now, we are not tracking anything at all.
import React, { useState, useEffect } from 'react';
import Privacy from './Privacy';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
    const [isPrivacyOpen, setIsPrivacyOpen] = useState(false);

  useEffect(() => {
    // Check if user has already consented
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true); // Show banner if no consent is found
    } else if (consent === 'accepted') {
      loadAnalytics(); // Load analytics if previously accepted
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    loadAnalytics(); // Load analytics on acceptance
    setIsVisible(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'declined');
    setIsVisible(false);
  };

  // Function to load Google Analytics or other tracking scripts
  const loadAnalytics = () => {
    // Replace with your Google Analytics script ID
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'YOUR_GOOGLE_ANALYTICS_ID'); // Replace with actual GA ID
  };

  if (!isVisible) return null;

  return (<>
    <div className="fixed bottom-0 left-0 right-0 bg-gray-800 text-white p-4 md:p-6 z-50 flex flex-col md:flex-row items-center justify-between text-sm md:text-base">
      <p className="text-center md:text-left mb-2 md:mb-0">
        We may use cookies to enhance your experience. By accepting, you agree to our{" "}
       <button
            onClick={() => setIsPrivacyOpen(true)} // Open the privacy policy pop-up
            className="underline text-yellow-400"
          >
            Privacy Policy
          </button>.
      </p>
      <div className="flex space-x-2">
        <button
          onClick={handleAccept}
          className="bg-yellow-400 text-black px-4 py-2 rounded-md font-medium"
        >
          Accept
        </button>
        <button
          onClick={handleDecline}
          className="bg-gray-600 text-white px-4 py-2 rounded-md font-medium"
        >
          Decline
        </button>
      </div>
    </div>
       {/* Privacy Policy Popup */}
      <Privacy isOpen={isPrivacyOpen} onClose={() => setIsPrivacyOpen(false)} />
    </>
  );
};

export default CookieConsent;
