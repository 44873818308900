import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import DataContext from './context/DataContext';
import Homepage from './components/Homepage';
import Privacy from "./components/Privacy"

function App() {
  return (
    <div className="font-raleway overflow-hidden">
      <DataContext.Provider value={{}}>
        <Routes>
          <Route path="/" element={<Navigate replace to="/homepage" />} />
          <Route path="/homepage" element={<Homepage />} />
           <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </DataContext.Provider>
    </div>
  );
}

export default App;
