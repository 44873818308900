import React, { useRef } from 'react';
import backgroundImage from '../assets/programs.jpg';
import { FaSquare, FaDownload, FaComments } from 'react-icons/fa';
import useInView from '../hooks/useInView';
import FadeInEffect from './FadeInEffect';

const Programs = () => {
  const programsRef = useRef(null);
  const isInView = useInView(programsRef); // Use the hook

  return (
    <div
      className="relative w-screen h-screen flex flex-col md:flex-row overflow-hidden"
      id="programs"
      ref={programsRef}
    >
      {/* BACKGROUND IMAGE */}{' '}
      <FadeInEffect
        isInView={isInView}
        disableTranslate={true}
        className="md:w-full md:h-full order-2 md:order-1"
      >
        <img src={backgroundImage} className="h-full w-full object-cover" />
      </FadeInEffect>
      {/* WHITE TEXT BOX */}
      <div className="z-10 md:absolute top-[13%] right-20 md:w-1/3 h-[75%] p-5 pl-8 md:pl-5 bg-white flex flex-col pt-8 order-1 md:order-2">
        <FadeInEffect
          isInView={isInView}
          disableTranslate={true}
          className="h-full w-full overflow-auto"
        >
          <div className="leading-none">
            <p className="text-2xl md:text-3xl font-extralight text-brand-yellow">
              PERSONALIZED PROGRAMS
            </p>
            <p className="text-3xl md:text-header font-extralight text-brand-yellow">
              HOW DOES IT WORK?
            </p>
          </div>
          <div className="h-4 md:h-6"></div>
          <p className="text-base md:text-xl leading-tight font-semibold">
            Tell me when you plan to come in Burgundy and what are your wishes for your travel.{' '}
            <br />
            Then you have 2 options:
          </p>
          <div className="h-4"></div>
          <div className="flex items-start">
            <div className="pt-2">
              <FaSquare className="text-brand-yellow text-[8px]" />
            </div>

            <div className="pl-2 font-light">
              <p className="text-base md:text-xl">
                I can plan and book the best places for you and send you your personalized program
                so that you can visit independently.
              </p>
              <p className="pt-1 text-xs">from 100€/up to 2 days of program</p>
            </div>
          </div>
          <div className="h-4"></div>
          <div className="flex items-start">
            <div className="pt-2">
              <FaSquare className="text-brand-yellow text-[8px]" />
            </div>

            <div className="pl-2 font-light">
              <p className="text-base md:text-xl">
                We can plan your visit together and I will be your private guide for one or more
                days
              </p>
              <p className="pt-1 text-xs">from 220€/day/pers</p>
            </div>
          </div>{' '}
          <div className="h-2"></div>
          {/* DOWNLOAD BUTTON */}
          <div className="my-4">
            <a
              href="/assets/program.pdf"
              download
              className="inline-flex items-center px-2 py-1 bg-brand-yellow text-white font-medium rounded transition hover:bg-yellow-600"
            >
              <FaDownload className="mr-2" />
              Download Sample Program
            </a>
          </div>
          <div
            className="md:pt-0 pt-4 font-extralight cursor-pointer text-right md:pr-8"
            onClick={() => {
              const element = document.getElementById('contactmeMobile');
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }}
          >
            ASK ME FOR A QUOTE
          </div>
        </FadeInEffect>
      </div>
    </div>
  );
};

export default Programs;
