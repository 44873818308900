import React from "react";

const FadeInEffect = ({
  children,
  isInView,
  delay = "150ms",
  duration = "500ms",
  className = "",
  disableTranslate = false, // New prop to disable translation
}) => {
  return (
    <div
      className={`transition-all transform ${
        isInView
          ? "opacity-100" + (disableTranslate ? "" : " translate-x-0 translate-y-0")
          : "opacity-0" + (disableTranslate ? "" : " translate-x-[-20%] translate-y-[-20%]")
      } ${className}`}
      style={{
        transitionDuration: duration,
        transitionDelay: delay,
      }}
    >
      {children}
    </div>
  );
};

export default FadeInEffect;
