import React from 'react';

const Privacy = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 px-4">
      <div className="privacy-popup relative bg-[#161615] p-6 md:p-12 rounded-lg max-w-3xl w-full text-brand-yellow text-sm md:text-base max-h-[70vh] overflow-y-auto mt-8 mb-8">
            {/* Fixed Close button */}
        <button
          onClick={onClose}
          className="absolute top-4 md:top-10 right-6 md:right-10 text-brand-yellow text-lg font-bold z-50"
          aria-label="Close"
        >
          &times;
        </button>

        {/* Privacy Policy Content */}
        <h2 className="text-2xl font-semibold mb-4 text-center">Privacy Policy</h2>

        <p className="mb-4">
          <strong>Website editor:</strong><br />
          Dai Dai Experience<br />
          Sylvie Pinsonneaux EI<br />
          Siret: 842 846 834 00013 – APE: 79 90 20<br />
          FR 95 842846834<br />
          Email : hello@daidaiexperience.com
        </p>

        <p className="mb-4">
          <strong>Publication Director:</strong><br />
          Mme Sylvie Pinsonneaux
        </p>

        <p className="mb-4">
          <strong>Design / Hosting / SEO:</strong><br />
          Webmaster: Mme Kellyn Chan<br />
          Hosting: Vercel, Inc.<br/>
          4600 East Washington Street, Suite 300, Phoenix, AZ 85034, USA<br/>
          Phone number : +1 661 310 2107 
        </p>

        <p className="mb-4">
          <strong>Photos:</strong><br />
          ©Sylvie Pinsonneaux – ©Yogamaga - ©Pexels Creative Commons license - ©Pexels Tim Durand - ©Freepik Creative Commons license - ©Bob Richards Stocksnap -
        </p>

        <p className="mb-4">
          <strong>Personal data:</strong><br />
          Personal data relating to Users, collected directly through forms and indirectly during their navigation to and on the site, and any future information, are intended for the exclusive use of the publisher, to process their information requests, reply to their emails and mail, and send them newsletters to which they may have subscribed, as well as for the establishment of internal statistics.
        </p>

        <ul className="list-disc ml-5 mb-4 space-y-2">
          <li>This data is not subject to any communication or transfer to third parties, other than any technical service providers possibly in charge of managing the daidaiexperience.com website, who are required to respect the confidentiality of the information and to use it only for the specific operation for which they must intervene.</li>
          <li>This data is kept for a period of 5 years following the date of their collection.</li>
          <li>In accordance with the provisions of the Law of January 6, 1978, and Regulation (EU) 2016/679 of April 27, 2016, on data protection, you have the right to access this data, as well as the right to modify, rectify, delete, and portability of this data.</li>
          <li>You can also object to any processing of this data, which, however, implies Dai Dai Experience will not be able to respond to your request in this case.</li>
        </ul>

        <p className="mb-4">
          You can exercise these rights at the following contact details:<br />
          Dai Dai Experience – hello@daidaiexperience.com<br />
          Sylvie Pinsonneaux EI - 3 rue des écoles 71150 Remigny
        </p>
      </div>
    </div>
  );
};

export default Privacy;
