import React, { useRef } from 'react';
import backgroundImage from '../assets/events.jpg';
import useInView from '../hooks/useInView';
import FadeInEffect from './FadeInEffect';

const Events = () => {
  const eventsRef = useRef(null);
  const isInView = useInView(eventsRef); // Use the hook

  return (
    <div
      className="relative w-screen h-screen flex flex-col md:flex-row"
      id="events"
      ref={eventsRef}
    >
      {/* BACKGROUND IMAGE */}
      <FadeInEffect
        isInView={isInView}
        disableTranslate={true}
        className="absolute top-[50%] md:top-0 md:w-full md:h-full order-2 md:order-1"
      >
        <img
          src={backgroundImage}
          className="h-full w-screen md:w-2/3 object-cover transform scale-x-[-1]"
        />
      </FadeInEffect>

      {/* WHITE TEXT BOX */}
      <div className="absolute top-[3%]  md:top-[25%] right-[10%] md:right-20 w-[80%] md:w-[36%] h-[46%] md:h-[65%] md:p-6 bg-white flex flex-col md:pt-12 order-1 md:order-2">
        <FadeInEffect
          isInView={isInView}
          disableTranslate={true}
          className="h-full w-7/8 md:w-full overflow-hidden"
        >
          <div className="md:leading-none">
            <p className="text-[1.7rem] md:text-header font-extralight text-brand-yellow">
              CUSTOM EVENTS <span className="text-xl md:text-3xl">FOR</span>
            </p>
            <p className="text-2xl md:text-3xl font-extralight text-brand-yellow">
              AN EXPERIENCE UNFORGETTABLE
            </p>
          </div>
          <div className="h-6"></div>
          <p className="text-lg md:text-xl font-light">
            You want to organize your event in Burgundy? I can make it happen!
            <br />
            You can tell me your desires, your needs and I will organize your day.
          </p>
          <div className="h-6"></div>
          <p className="text-xs md:text-sm font-medium">
            Team building in a castle, marriage in an unexpected place, picnic in the vineyards,
            cooking class, business seminar, ...
            <span className="font-light">
              <br />
              We talk about it and I will send you my proposals so that your event is like in your
              dreams
            </span>
          </p>
          <div className="h-6"></div>
          <div
            className="md:block hidden font-extralight cursor-pointer text-right pr-8"
            onClick={() => {
              const element = document.getElementById('contactme');
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }}
          >
            ASK ME MORE
          </div>
        </FadeInEffect>
      </div>
    </div>
  );
};

export default Events;
