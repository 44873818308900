import React, { useRef } from 'react';
import backgroundImage from '../assets/summary.jpg';
import useInView from '../hooks/useInView';

const Summary = () => {
  const summaryRef = useRef(null);
  const isInView = useInView(summaryRef); // Use the hook

  const handleClick = (key) => {
    const bestSpotsElement = document.getElementById('bestSpots');
    if (bestSpotsElement) {
      bestSpotsElement.scrollIntoView({ behavior: 'smooth' });
      // Dispatch a custom event with the selected category
      window.dispatchEvent(new CustomEvent('categorySelected', { detail: key }));
    }
  };

  return (
    <div className="h-screen w-screen relative flex" id="summary" ref={summaryRef}>
      <div
        className={`w-1/6 transition-opacity duration-[1500ms] ${isInView ? 'opacity-100' : 'opacity-0'}`}
      ></div>
      <img
        src={backgroundImage}
        className={`md:w-5/6 object-cover transition-opacity duration-[1500ms] transform ${
          isInView
            ? 'opacity-100 translate-x-0 translate-y-0'
            : 'opacity-0 translate-x-[-20%] translate-y-[-20%]'
        } `}
        style={{ objectPosition: 'center 50%', transform: 'scaleX(-1)' }}
      />

      {/* Text Box */}
      <div
        className={`absolute left-0 top-[60%] md:top-[50%] w-9/12 md:w-1/2 pl-4 md:pl-24 pr-4 md:pr-0 pt-4 md:pt-8 pb-4 md:pb-8 bg-white flex flex-col  transition-opacity duration-[1500ms] ${
          isInView
            ? 'opacity-100 translate-x-0 translate-y-0'
            : 'opacity-0 translate-x-[-20%] translate-y-[-20%]'
        }`}
        style={{ transform: 'translateY(-50%)', zIndex: 10 }} // this makes white box in middle of page
      >
        <div className="space-y-[-0.9rem] text-[1.7rem] md:text-header font-extralight text-[#fbb80a]">
          {['TASTING', 'SHOPPING', 'FAMILY', 'GASTRONOMY', 'CULTURE', 'SPORTS', 'WELLNESS'].map(
            (key, index) => (
              <div
                key={key}
                className={`hover:text-black cursor-pointer relative group transition-transform duration-[1500ms] ${
                  isInView
                    ? 'translate-x-0 translate-y-0 opacity-100'
                    : 'translate-x-[-20%] translate-y-[-20%] opacity-0'
                }`}
                style={{ transitionDelay: `${index * 150}ms` }}
                onClick={() => handleClick(key)}
              >
                <p className="">{key}</p>
              </div>
            )
          )}
        </div>{' '}
        {/* Spacer */}
        <div className="h-2 md:h-6"></div>
        <div
          className={`transition-transform duration-[1500ms] ${
            isInView ? 'translate-y-0 opacity-100' : 'translate-y-[-20%] opacity-0'
          }`}
          style={{ transitionDelay: '1050ms' }}
        >
          <div className="text-sm text-black leading-snug">
            <div className="font-semibold">
              {' '}
              Do you want to come to Burgundy and discover the region as if you were being welcomed
              by a local friend?
              <br />
              <span className="font-light">
                Look no further, I am here to advise you and guide you in your wanderings! All this
                with good humor and efficiency. I adapt to your desires to create with you the visit
                that excites you.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
